<template>
  <div class="jurisdiction">
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="菜单名称">
					<el-input v-model="formSearch.name" placeholder="请输入菜单名称"></el-input>
				</el-form-item>
				<el-form-item label="父级菜单">
					<el-input v-model="formSearch.parentName" placeholder="请输入父级菜单"></el-input>
				</el-form-item>
				<el-form-item label="菜单级别">
					<el-select v-model="formSearch.level" clearable>
						<el-option label="一级" value="1"></el-option>
						<el-option label="二级" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="菜单路由">
					<el-input v-model="formSearch.path" placeholder="请输入路由"></el-input>
				</el-form-item>
				<el-form-item label="创建时间">
					<el-date-picker v-model="formSearch.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"></el-date-picker>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="handleSearch">检索</el-button>
					<el-button type="success" @click="handleAdd">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<div class="table-box">
			<el-card>
				<el-table v-loading="loadingFlag" border :data="tableData" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="name" label="菜单名称" width="300"></el-table-column>
					<el-table-column prop="parentName" label="父级菜单" width="100"></el-table-column>
					<el-table-column prop="level" label="菜单级别" width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.level == '1'">一级菜单</span>
							<span v-if="scope.row.level == '2'">二级菜单</span>
						</template>
					</el-table-column>
					<el-table-column prop="path" label="菜单路由"></el-table-column>
					<el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
					<el-table-column prop="" label="操作" width="100">
						<template slot-scope="scope">
							<span @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;" class="color-m">编辑</span>
							|
							<span @click="handDelete(scope.$index, scope.row)" style="color: red;cursor: pointer;">删除</span>
						</template>
					</el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</el-card>
		</div>
		<!-- 添加  编辑     弹框 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="菜单名称" prop="name">
					<el-input v-model="ruleForm.name" placeholder="请输入菜单名称"></el-input>
				</el-form-item>
				<el-form-item label="菜单级别" prop="level">
					<el-select v-model="ruleForm.level" @change="handleLevel">
						<el-option label="一级菜单" value="1"></el-option>
						<el-option label="二级菜单" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="父级菜单" prop="parentId">
					<el-select v-model="ruleForm.parentId" placeholder="请选择父级菜单" :disabled="disabledFlag">
						<el-option v-for="(item,index) in parentList" :key="index" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="菜单路由" prop="path">
					<el-input v-model="ruleForm.path" placeholder="请输入路由"></el-input>
				</el-form-item>
				<el-form-item label="菜单状态" prop="status">
					<el-select v-model="ruleForm.status">
						<el-option label="启用" value="0"></el-option>
						<el-option label="停用" value="1"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</span>
		</el-dialog>
  </div>
</template>

<script>
export default {
  name: 'jurisdiction',
  data() {
		return {
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			formSearch: {
				name: '',
				parentName: '',
				level: '',
				path:'',
				time: null,
			},
			loadingFlag: false,
			dataTableEmpty:false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 13, //每页  条数
			totalItems: 0, //列表 总数
			dialogVisible: false,
			dialogTitle: "权限新增",
			disabledFlag:false,
			parentList:[],
			ruleForm: {
				id: '',
				name: '',
				level: '1',
				parentId: '',
				path: '',
				status: '',
			},
			rules: {
				name: [
					{ required: true, message: '请输入菜单名称', trigger: 'blur' },
				],
				level: [
					{ required: true, message: '请选择菜单级别', trigger: 'change' }
				],
				path: [
					{ required: true, message: '请填写路由', trigger: 'blur' }
				]
			}
		};
  },
  mounted() {}, 
  created() {
		this.getPageList(); //加载列表
		this.getParentList();//加载父级菜单
  },
  methods: {
		//点击查询  按钮
		handleSearch() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true;//loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-system/menu/page', {
				params: {
					name: this.formSearch.name,
					parentName: this.formSearch.parentName,
					level: this.formSearch.level,
					path: this.formSearch.path,
					startTime: this.formSearch.time != null ? this.formSearch.time[0] : "",
					endTime: this.formSearch.time != null ? this.formSearch.time[1] : "",
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res => {
				this.loadingFlag = false;//loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//删除
		handDelete(index,item){
			if(confirm("确定删除本条数据吗？")){
				this.$provider.get('/wg-system/menu/delete', {
					params: {
						id: item.id,
					}
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '删除成功',
							type: 'success'
						});
						this.getPageList();
					} else {
						this.$message.error('删除失败');
						return false;
					}
				})
			}
		},
		//编辑  弹框打开
		handleEdit(index,item) {
			this.dialogVisible = true; //打开弹框
			this.dialogTitle = "权限编辑";
			this.ruleForm.id = item.id;
			this.ruleForm.name = item.name;
			this.ruleForm.level = item.level;
			this.ruleForm.parentId = item.parentId;
			this.ruleForm.path = item.path;
			this.ruleForm.status = item.status;
		},
		//添加  弹框打开
		handleAdd() {
			this.dialogVisible = true; //打开弹框
			this.dialogTitle = "权限新增";
			this.ruleForm.id = '';
			this.ruleForm.name = '';
			this.ruleForm.level = '1';
			this.ruleForm.parentId = '';
			this.ruleForm.path = '';
			this.ruleForm.status = '';
		},
		//加载父级菜单
		getParentList() {
			this.$provider.get('/wg-system/menu/level', {}).then(res => {
				this.parentList = res.data["1"];
			})
		},
		handleLevel(val){
			if(val == "1"){
				this.disabledFlag = true;
				this.ruleForm.parentId = '';
			} else {
				this.disabledFlag = false;
			}
		},
		//添加  编辑  确定
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.dialogTitle == "权限新增") {
						this.$provider.post('/wg-system/menu/insert', {
							id: this.ruleForm.id,
							name: this.ruleForm.name,
							level: this.ruleForm.level,
							parentId: this.ruleForm.parentId,
							path: this.ruleForm.path,
							status: this.ruleForm.status,
						}).then(res => {
							if (res.code == 200) {
								this.$message({
									message: '添加成功',
									type: 'success'
								});
								this.dialogVisible = false; //关闭弹框
								this.getPageList();
							} else {
								this.$message.error('添加失败');
								return false;
							}
						})
					} else if (this.dialogTitle == "权限编辑") {
						this.$provider.post('/wg-system/menu/update', {
							id: this.ruleForm.id,
							name: this.ruleForm.name,
							level: this.ruleForm.level,
							parentId: this.ruleForm.parentId,
							path: this.ruleForm.path,
							status: this.ruleForm.status,
						}).then(res => {
							if (res.code == 200) {
								this.$message({
									message: '编辑成功',
									type: 'success'
								});
								this.dialogVisible = false; //关闭弹框
								this.getPageList();
							} else {
								this.$message.error('编辑失败');
								return false;
							}
						})
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
  }
};
</script>

<style scoped="scoped"></style>
